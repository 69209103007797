import React, { useEffect } from 'react'

type Props = {}
const RedirectURL = 'https://kanhaone.page.link/bandara-event';
const clipboardURL = "https://kanhaone.page.link/?link=https://heartfulness.app/?path%3Dbhandara-screen&apn=org.heartfulness.heartintune.prod&isi=1576857513&ibi=org.heartfulness.heartintune.prod";
const App = (props: Props) => {
  useEffect(() => {
    window.location.href = RedirectURL;
  }, []);

  // Adds text to client's clipboard
  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(clipboardURL);
  };

  return (
    <div>Redirecting....</div>
  )
}

export default App;